import { DraftDocumentPosition, TradingDocument } from "api/trading-documents/models";
import styles from "./DocumentPositions.module.css";
import { Table } from "components/miloDesignSystem/molecules/table";
import { comfortableListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { useDocumentPositionsColumns } from "./useDocumentPositionsColumns";
import { EmptySection } from "components/common/emptySection/EmptySection";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";

interface Props {
  draftInvoice: TradingDocument;
}

export const DocumentPositions = ({ draftInvoice }: Props) => {
  const columns = useDocumentPositionsColumns(draftInvoice);
  const normalizedRows = getNormalizedTradingDocumentItems(draftInvoice.items);

  const tableProps = useTableFeatureConnector({
    rows: normalizedRows.sort((a, b) => a.displayPosition - b.displayPosition),
  });

  return (
    <div className={styles.tableContainer}>
      <Table<DraftDocumentPosition>
        columns={columns}
        isLoading={false}
        error={null}
        uiSchema={comfortableListUiSchema}
        {...tableProps}
        overrides={() => ({
          ...tableProps?.overrides?.(),
          noResultComponent: (
            <div className="mt-2">
              <EmptySection label="Brak pozycji na fakturze" />
            </div>
          ),
        })}
      />
    </div>
  );
};

const getNormalizedTradingDocumentItems = (
  items: TradingDocument["items"],
): DraftDocumentPosition[] => {
  return items.flatMap(item =>
    item.tradingDocumentItems.map(tradingDocumentItem => {
      return {
        ...tradingDocumentItem,
        order: item.order,
      };
    }),
  );
};
